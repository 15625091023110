<template>
  <div class="download">
    <div class="container">

        <div class="qrCode">
            <img :src="imgUrl.qrCode" alt="下载App二维码">
        </div>
        <div class="desc">
            <h1>扫一扫，下载卖座电影App</h1>
            <p>看电影买电影票在线订座就用卖座电影，卖座电影为您提供全国400多个城市，4000多家影院影讯、电影排期查询、在线订座购票、自动退费服务。新用户购票5元起，每周都有特价活动。</p>
            <div class="download_type">
                <div class="apple" @click="goTo('https://itunes.apple.com/cn/app/id558468327?mt=8')">
                  <img :src="imgUrl.typeApple" >
                </div>
                <div class="android" @click="goTo('http://android.myapp.com/myapp/detail.htm?apkName=com.hyx.maizuo.main&ADTAG=mobile')">
                    <img :src="imgUrl.typeAndroid">
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgUrl: {
        qrCode: 'https://mall.s.maizuo.com/9d32168367f990ebc568d87f94658cce.jpg',
        typeApple:
          "http://mall.s.maizuo.com/9eb1b1f4046bcc5af0e43d478b10e2f3.jpg",
        typeAndroid:
          "http://mall.s.maizuo.com/a97ef017e6c962f8ab1bf3dbfd3f8aca.jpg"
      }
    };
  },
  methods: {
    goTo: function (url) {
      window.location.href = url;
    }
  }
};
</script>

<style lang="less">
.download {
  width: 100%;
  min-height: 694px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    overflow: hidden;
    margin-top: -65px;
    .qrCode {
      float: left;
      width: 384px;
      height: 384px;
      margin-right: 64px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .desc {
      float: right;
      width: 520px;
      padding-top: 68px;
      h1 {
        margin: 0;
        padding: 0;
        margin-bottom: 30px;
        font-size: 36px;
        color: #222426;
        font-weight: normal;
      }
      p {
        margin: 0;
        color: #787878;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 50px;
        font-weight: normal;
      }
      .download_type {
        .apple,
        .android {
          width: 186px;
          height: 52px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .apple {
          display: inline-block;
          margin-right: 30px;
        }
        .android {
          display: inline-block;
        }
      }
    }
  }
}
</style>

